import { combineReducers } from 'redux';
import {
  TEAM_LEAGUE_TABLE_PAGE_REQUEST,
  TEAM_LEAGUE_TABLE_PAGE_RECEIVE,
  TEAM_LEAGUE_TABLE_PAGE_FAILURE,
} from 'store/action-types';
import { reject, uniq } from 'lodash';

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case TEAM_LEAGUE_TABLE_PAGE_REQUEST:
      return [...state, action.payload.teamId];
    case TEAM_LEAGUE_TABLE_PAGE_FAILURE:
    case TEAM_LEAGUE_TABLE_PAGE_RECEIVE: {
      const { teamId } = action.payload;
      const teamIds = [...state];
      return reject(teamIds, (loadingId) => loadingId === teamId);
    }
    default:
      return state;
  }
};

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case TEAM_LEAGUE_TABLE_PAGE_RECEIVE:
      return uniq([...state, action.payload.teamId]);
    default:
      return state;
  }
};

const loadingError = (state = [], action) => {
  switch (action.type) {
    case TEAM_LEAGUE_TABLE_PAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case TEAM_LEAGUE_TABLE_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const seasons = (state = {}, action) => {
  switch (action.type) {
    case TEAM_LEAGUE_TABLE_PAGE_RECEIVE: {
      const { teamId, seasons: teamSeasons } = action.payload;
      return { ...state, [teamId]: teamSeasons };
    }
    default:
      return state;
  }
};

const tables = (state = {}, action) => {
  switch (action.type) {
    case TEAM_LEAGUE_TABLE_PAGE_RECEIVE: {
      const { teamId, tables: teamTables } = action.payload;
      return { ...state, [teamId]: teamTables };
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoading,
  hasLoaded,
  loadingError,
  seasons,
  tables,
});
