import { combineReducers } from 'redux';
import { BOOKINGS_WIDGET_RECEIVE } from 'store/action-types';

const widget = (state = {}, action) => {
  switch (action.type) {
    case BOOKINGS_WIDGET_RECEIVE:
      // eslint-disable-next-line no-case-declarations
      const { widgets } = action.payload.widgetsData;

      return widgets[0];
    default:
      return state;
  }
};

const scriptUrl = (state = '', action) => {
  switch (action.type) {
    case BOOKINGS_WIDGET_RECEIVE:
      // eslint-disable-next-line no-case-declarations
      const { script_url: url } = action.payload.widgetsData;

      return url;
    default:
      return state;
  }
};

export default combineReducers({
  widget,
  scriptUrl,
});
