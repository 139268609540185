import { combineReducers } from 'redux';

const passThrough = (state = '') => {
  return state;
};

export default combineReducers({
  host: passThrough,
  isApp: passThrough,
});
