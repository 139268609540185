/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { getLoggedInUser } from 'store/membership/selectors';
import { setAdvertsSetup } from 'store/page-info/actions';

import {
  isLoggedIn as getIsLoggedIn,
  hasCheckedLoginState as getHasCheckedLoginState,
} from 'store/auth/selectors';

function addScriptsToDOM() {
  const script = document.createElement('script');

  script.classList.add('advertising-script');
  script.async = true;
  script.src = 'https://www.googletagservices.com/tag/js/gpt.js';

  const firstScriptOnPage = document.getElementsByTagName('script')[0];
  firstScriptOnPage.parentNode.insertBefore(script, firstScriptOnPage);
}

function setTargeting(pageType, club, user) {
  const {
    advertisingTier,
    colours: { primary: primaryColor },
    gender,
    id,
    isYouth,
    name,
    postcode,
    sport,
  } = club;

  function getPitcheroSkinsValue() {
    if (advertisingTier === 1) {
      return 'yes';
    }

    return 'no';
  }

  window.googletag.pubads().setTargeting('Pitchero_KW_Test', ['']);
  window.googletag.pubads().setTargeting('Pitchero_Tiers', `tier${advertisingTier}`);
  window.googletag.pubads().setTargeting('Pitchero_Sports', sport);
  window.googletag.pubads().setTargeting('Pitchero_Platform', 'club');
  window.googletag.pubads().setTargeting('Pitchero_Club_ID', id.toString());
  window.googletag.pubads().setTargeting('Pitchero_Club_Name', name);
  window.googletag.pubads().setTargeting('Pitchero_Club_Post', postcode);
  window.googletag.pubads().setTargeting('Pitchero_Link_Colour', primaryColor);
  window.googletag.pubads().setTargeting('Pitchero_PH_Gender', gender);
  window.googletag.pubads().setTargeting('Pitchero_Youth', isYouth ? 'yes' : 'no');
  window.googletag.pubads().setTargeting('Pitchero_Media_Type', pageType);
  window.googletag.pubads().setTargeting('Pitchero_Template', 'new');
  window.googletag.pubads().setTargeting('Pitchero_Skins', getPitcheroSkinsValue());

  if (user) {
    const {
      group,
      isPlayer = false,
      isParent = false,
      isCoach = false,
      isTeamAdmin = false,
      isWebmaster = false,
      isStaffOfficial = false,
      isClubMember = false,
    } = user;

    window.googletag.pubads().setTargeting('Pitchero_Group', group);
    window.googletag.pubads().setTargeting('Pitchero_Player', isPlayer ? 'Yes' : 'No');
    window.googletag.pubads().setTargeting('Pitchero_Parent', isParent ? 'Yes' : 'No');
    window.googletag.pubads().setTargeting('Pitchero_Coach', isCoach ? 'Yes' : 'No');
    window.googletag.pubads().setTargeting('Pitchero_Team_Admin', isTeamAdmin ? 'Yes' : 'No');
    window.googletag.pubads().setTargeting('Pitchero_Webmaster', isWebmaster ? 'Yes' : 'No');
    window.googletag
      .pubads()
      .setTargeting('Pitchero_Staff_Official', isStaffOfficial ? 'Yes' : 'No');

    window.googletag.pubads().setTargeting('Pitchero_Club_Member', isClubMember ? 'Yes' : 'No');
  }
}

async function startTargeting(pageType, club, user, callback) {
  addScriptsToDOM();

  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  window.googletag.cmd.push(function init() {
    setTargeting(pageType, club, user);
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.pubads().enableSingleRequest();
    window.googletag.enableServices();
  });

  return callback();
}

function cleanupAdTracking() {
  const elements = document.getElementsByClassName('advertising-script');
  while (elements[0]) {
    elements[0].parentNode.removeChild(elements[0]);
  }
  window.googletag = undefined;
}

const AdTrackingController = ({
  club,
  hasCheckedLoginState,
  pageType,
  setAdvertsSetup: updateAdvertsSetup,
  user,
  isLoggedIn,
}) => {
  // if the club has includeAdverts turned off..
  if (club && !club.includeAdverts) {
    return null;
  }

  const [adsRequested, setAdsRequested] = useState(false);

  useEffect(() => {
    // We are sending off some user specific data to the ad server
    // isLoggedIn is a value sent from the node server, if it is false
    // no one is logged in and we can request the ads.
    // if isLoggedIn is true we need to wait until the hasCheckedLoginState
    // value is true, that way we are guaranteed to have a user and will be able
    // to send all the correct values to the ads server
    if (!isLoggedIn || (isLoggedIn && hasCheckedLoginState && !adsRequested)) {
      setAdsRequested(true);

      startTargeting(pageType, club, user, function callback() {
        updateAdvertsSetup(true);
      });

      return function cleanup() {
        updateAdvertsSetup(false);
        cleanupAdTracking();
      };
    }

    return function cleanup() {};
  }, [isLoggedIn, hasCheckedLoginState]);

  return null;
};

AdTrackingController.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  pageType: PropTypes.string.isRequired,
  setAdvertsSetup: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  hasCheckedLoginState: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

AdTrackingController.defaultProps = {
  user: undefined,
};

const mapStateToProps = (state) => ({
  hasCheckedLoginState: getHasCheckedLoginState(state),
  user: getLoggedInUser(state),
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps, { setAdvertsSetup })(AdTrackingController);
