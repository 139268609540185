import React from 'react';
import { Cushion, Typography } from '@pitchero/react-ui';
import ArrowRightIcon from 'icons/ArrowRight';
import ClubLink from 'components/club-link';
import getNavItems from 'components/club-frame/header/nav-items';
import IconWithTheme from 'components/icon-with-theme';
import { map } from 'lodash';
import { withTranslation, Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';

const ErrorLinks = ({ club = {} }) => {
  const navItems = getNavItems({ activeSection: false, club, limitTeams: true });
  return (
    <>
      {map(navItems, (navItem) => {
        return (
          <Cushion all="xsmall" component="div" key={navItem.id}>
            <ClubLink currentClub={club} {...navItem.routeParams}>
              <a>
                <Cushion all="small">
                  <Typography
                    align="center"
                    color="primary"
                    preset="button"
                    component="div"
                    style={{ cursor: 'pointer' }}
                  >
                    {navItem.transKey ? (
                      <Trans i18nKey={navItem.transKey}>{navItem.transKey}</Trans>
                    ) : (
                      navItem.name
                    )}
                    <Cushion left="xsmall">
                      <IconWithTheme>
                        <ArrowRightIcon
                          size={14}
                          color="primary"
                          style={{ marginBottom: '-2px' }}
                        />
                      </IconWithTheme>
                    </Cushion>
                  </Typography>
                </Cushion>
              </a>
            </ClubLink>
          </Cushion>
        );
      })}
    </>
  );
};

ErrorLinks.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default withTranslation(['common'])(ErrorLinks);
