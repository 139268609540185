import button from 'lib/club-theme/button';
import typography from 'lib/club-theme/typography';
import spacing from 'lib/club-theme/spacing';
import shadows from 'lib/club-theme/shadows';
import breakpoints from 'lib/club-theme/breakpoints';
import colors from './colors';

export default {
  button: button(colors),
  colors,
  typography,
  spacing,
  shadows,
  breakpoints,
};
