import { combineReducers } from 'redux';
import {
  TEAM_FIXTURES_PAGE_REQUEST,
  TEAM_FIXTURES_PAGE_RECEIVE,
  TEAM_FIXTURES_PAGE_FAILURE,
} from 'store/action-types';
import { reject, merge, keyBy, groupBy } from 'lodash';

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case TEAM_FIXTURES_PAGE_REQUEST: {
      const { teamId, seasonId } = action.payload;
      return [...state, [teamId, seasonId].join('-')];
    }
    case TEAM_FIXTURES_PAGE_FAILURE:
    case TEAM_FIXTURES_PAGE_RECEIVE: {
      const { teamId, seasonId } = action.payload;
      const ids = [...state];
      return reject(ids, (loadingId) => loadingId === [teamId, seasonId].join('-'));
    }
    default:
      return state;
  }
};

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case TEAM_FIXTURES_PAGE_RECEIVE: {
      const { teamId, seasonId } = action.payload;
      return [...state, [teamId, seasonId].join('-')];
    }
    default:
      return state;
  }
};

const loadingError = (state = [], action) => {
  switch (action.type) {
    case TEAM_FIXTURES_PAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case TEAM_FIXTURES_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const competitions = (state = {}, action) => {
  switch (action.type) {
    case TEAM_FIXTURES_PAGE_RECEIVE: {
      const { teamId, competitions: teamCompetitions } = action.payload;
      return {
        ...state,
        [teamId]: merge({ ...state[teamId] }, groupBy(keyBy(teamCompetitions, 'id'), 'seasonId')),
      };
    }
    default:
      return state;
  }
};

const fixtures = (state = {}, action) => {
  switch (action.type) {
    case TEAM_FIXTURES_PAGE_RECEIVE: {
      const { teamId, matchFixtures } = action.payload;
      return { ...state, [teamId]: merge({ ...state[teamId] }, keyBy(matchFixtures, 'id')) };
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoading,
  hasLoaded,
  loadingError,
  competitions,
  fixtures,
});
