import { combineReducers } from 'redux';
import {
  TEAM_SQUAD_PAGE_REQUEST,
  TEAM_SQUAD_PAGE_RECEIVE,
  TEAM_SQUAD_PAGE_FAILURE,
  TEAM_PROFILE_PAGE_REQUEST,
  TEAM_PROFILE_PAGE_RECEIVE,
  TEAM_PROFILE_PAGE_FAILURE,
} from 'store/action-types';
import { reject, uniq, sortBy, map } from 'lodash';

const sortCoaches = ({ members = [], ...group }) => ({
  ...group,
  members: sortBy(members, ['positionRank', 'formalName']),
});

const sortPlayers = ({ members = [], ...group }) => ({
  ...group,
  members: sortBy(members, ['formalName']),
});

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case TEAM_SQUAD_PAGE_REQUEST:
      return [...state, action.payload.teamId];
    case TEAM_SQUAD_PAGE_RECEIVE:
    case TEAM_SQUAD_PAGE_FAILURE: {
      const { teamId } = action.payload;
      const teamIds = [...state];
      return reject(teamIds, (loadingId) => loadingId === teamId);
    }
    default:
      return state;
  }
};

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case TEAM_SQUAD_PAGE_RECEIVE:
      return uniq([...state, action.payload.teamId]);
    default:
      return state;
  }
};

const loadingError = (state = [], action) => {
  switch (action.type) {
    case TEAM_SQUAD_PAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case TEAM_SQUAD_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const players = (state = {}, action) => {
  switch (action.type) {
    case TEAM_SQUAD_PAGE_RECEIVE:
      return { ...state, [action.payload.teamId]: map(action.payload.players, sortPlayers) };
    default:
      return state;
  }
};

const staffAndCoaches = (state = {}, action) => {
  switch (action.type) {
    case TEAM_SQUAD_PAGE_RECEIVE:
      return {
        ...state,
        [action.payload.teamId]: map(action.payload.staffAndCoaches, sortCoaches),
      };
    default:
      return state;
  }
};

const profiles = (state = {}, action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_RECEIVE: {
      const {
        profileId,
        teamId,
        data: { profile },
      } = action.payload;
      return {
        ...state,
        [`${teamId}-${profileId}`]: profile,
      };
    }
    default:
      return state;
  }
};

const profileFixtures = (state = {}, action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_RECEIVE: {
      const {
        data: { fixtures },
        profileId,
        seasonId,
        teamId,
      } = action.payload;
      return {
        ...state,
        [`${teamId}-${profileId}-${seasonId}`]: fixtures,
      };
    }
    default:
      return state;
  }
};

const profileStats = (state = {}, action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_RECEIVE: {
      const {
        data: { stats },
        profileId,
        seasonId,
        teamId,
      } = action.payload;
      return {
        ...state,
        [`${teamId}-${profileId}-${seasonId}`]: stats,
      };
    }
    default:
      return state;
  }
};

const loadedProfiles = (state = [], action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_RECEIVE:
      return uniq([...state, action.payload.data.id]);
    default:
      return state;
  }
};

const loadingProfiles = (state = [], action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_REQUEST: {
      const { teamId, profileId, seasonId } = action.payload;
      return uniq([...state, `${teamId}-${profileId}-${seasonId}`]);
    }
    case TEAM_PROFILE_PAGE_RECEIVE:
    case TEAM_PROFILE_PAGE_FAILURE: {
      const { teamId, profileId, seasonId } = action.payload;
      return reject([...state], (loadingId) => loadingId === `${teamId}-${profileId}-${seasonId}`);
    }
    default:
      return state;
  }
};

const profileLoadingErrors = (state = [], action) => {
  switch (action.type) {
    case TEAM_PROFILE_PAGE_RECEIVE: {
      const { teamId, profileId, seasonId } = action.payload;
      return reject(state, (error) => error.id === `${teamId}-${profileId}-${seasonId}`);
    }
    case TEAM_PROFILE_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoading,
  hasLoaded,
  loadingError,
  players,
  staffAndCoaches,
  profiles,
  profileFixtures,
  profileStats,
  loadingProfiles,
  loadedProfiles,
  profileLoadingErrors,
});
