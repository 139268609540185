import { combineReducers } from 'redux';
import { find, get, reject, uniqBy } from 'lodash';
import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  COMMENTS_PERMISSIONS_REQUEST,
  COMMENTS_PERMISSIONS_RECEIVE,
  COMMENTS_PERMISSIONS_FAILURE,
  COMMENTS_REQUEST,
  COMMENTS_RECEIVE,
  COMMENTS_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  RESTORE_COMMENT_REQUEST,
  RESTORE_COMMENT_SUCCESS,
  RESTORE_COMMENT_FAILURE,
} from 'store/action-types';

const loadingComments = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_REQUEST: {
      const { clubId, mediaType, mediaId } = action.payload;
      const currentClubLoading = get(state, clubId, []);
      return {
        ...state,
        [clubId]: [...currentClubLoading, `${mediaType}-${mediaId}`],
      };
    }
    case COMMENTS_FAILURE:
    case COMMENTS_RECEIVE: {
      const { clubId, mediaType, mediaId } = action.payload;
      const currentlyLoading = get(state, clubId, []);
      return {
        ...state,
        [clubId]: reject(currentlyLoading, (loadingId) => loadingId === `${mediaType}-${mediaId}`),
      };
    }
    default:
      return state;
  }
};

const loadedComments = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_RECEIVE: {
      const { clubId, mediaType, mediaId } = action.payload;
      const loadedClubComments = get(state, clubId, []);
      return {
        ...state,
        [clubId]: [...loadedClubComments, `${mediaType}-${mediaId}`],
      };
    }
    default:
      return state;
  }
};

const loadingFailures = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_FAILURE: {
      const { clubId, mediaType, mediaId } = action.payload;
      const previousClubFailures = get(state, clubId, []);
      return {
        ...state,
        [clubId]: [...previousClubFailures, `${mediaType}-${mediaId}`],
      };
    }
    case COMMENTS_RECEIVE: {
      const { clubId, mediaType, mediaId } = action.payload;
      const previousClubFailures = get(state, clubId, []);
      return {
        ...state,
        [clubId]: reject(
          previousClubFailures,
          (failureId) => failureId === `${mediaType}-${mediaId}`,
        ),
      };
    }
    default:
      return state;
  }
};

const canComment = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_PERMISSIONS_RECEIVE: {
      const { clubId, canComment: receivedValue } = action.payload;
      return {
        ...state,
        [clubId]: receivedValue,
      };
    }
    default:
      return state;
  }
};

const canAdminister = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_PERMISSIONS_RECEIVE: {
      const { clubId, canAdminister: receivedValue } = action.payload;
      return {
        ...state,
        [clubId]: receivedValue,
      };
    }
    default:
      return state;
  }
};

const currentlySubmittingTopLevelComments = (state = [], action) => {
  switch (action.type) {
    case ADD_COMMENT_REQUEST: {
      const { clubId, mediaType, mediaId, replyingTo } = action.payload;
      if (replyingTo) {
        return state;
      }

      return [...state, `${clubId}-${mediaType}-${mediaId}`];
    }

    case ADD_COMMENT_FAILURE:
    case ADD_COMMENT_SUCCESS: {
      const { clubId, mediaType, mediaId } = action.payload;
      return reject(state, (loadingId) => loadingId === `${clubId}-${mediaType}-${mediaId}`);
    }
    default:
      return state;
  }
};

const updateDeletingStatus = (state, clubId, commentId, parentId, isDeleting) => {
  const currentClubComments = get({ ...state }, clubId, []);
  if (parentId) {
    const parent = find(currentClubComments, { id: parentId });
    if (!parent) {
      return state;
    }

    const comment = find(parent.replies, { id: commentId });
    if (!comment) {
      return state;
    }

    comment.isDeleting = isDeleting;
    parent.replies = uniqBy([comment, ...parent.replies], 'id');
    return {
      ...state,
      [clubId]: uniqBy([parent, ...currentClubComments], 'id'),
    };
  }

  const comment = find(currentClubComments, { id: commentId });
  if (!comment) {
    return state;
  }

  comment.isDeleting = isDeleting;

  return {
    ...state,
    [clubId]: uniqBy([comment, ...currentClubComments], 'id'),
  };
};

const updateRestoringStatus = (state, clubId, commentId, parentId, isRestoring) => {
  const currentClubComments = get({ ...state }, clubId, []);
  if (parentId) {
    const parent = find(currentClubComments, { id: parentId });
    if (!parent) {
      return state;
    }

    const comment = find(parent.replies, { id: commentId });
    if (!comment) {
      return state;
    }

    comment.isRestoring = isRestoring;
    parent.replies = uniqBy([comment, ...parent.replies], 'id');
    return {
      ...state,
      [clubId]: uniqBy([parent, ...currentClubComments], 'id'),
    };
  }

  const comment = find(currentClubComments, { id: commentId });
  if (!comment) {
    return state;
  }

  comment.isRestoring = isRestoring;

  return {
    ...state,
    [clubId]: uniqBy([comment, ...currentClubComments], 'id'),
  };
};

const insertOrUpdateComment = (state, clubId, comment) => {
  const { parentId } = comment;
  const currentClubComments = get({ ...state }, clubId, []);
  if (parentId) {
    const parent = find(currentClubComments, { id: parentId });
    if (!parent) {
      return state;
    }

    parent.replies = uniqBy([comment, ...parent.replies], 'id');
    return {
      ...state,
      [clubId]: uniqBy([parent, ...currentClubComments], 'id'),
    };
  }

  return {
    ...state,
    [clubId]: uniqBy([comment, ...currentClubComments], 'id'),
  };
};

const updateSubmittingReplyStatus = (state, clubId, commentId, isSubmittingReply) => {
  const currentClubComments = get({ ...state }, clubId, []);
  const comment = find(currentClubComments, { id: commentId });
  if (comment) {
    comment.isSubmittingReply = isSubmittingReply;
    return {
      ...state,
      [clubId]: uniqBy([comment, ...currentClubComments], 'id'),
    };
  }

  return state;
};

const comments = (state = {}, action) => {
  switch (action.type) {
    case COMMENTS_RECEIVE: {
      const { clubId, comments: receivedComments } = action.payload;
      const currentClubComments = get(state, clubId, []);
      return {
        ...state,
        [clubId]: uniqBy([...receivedComments, ...currentClubComments], 'id'),
      };
    }
    case ADD_COMMENT_REQUEST: {
      const { clubId, replyingTo } = action.payload;
      if (replyingTo) {
        return updateSubmittingReplyStatus(state, clubId, replyingTo, true);
      }

      return state;
    }
    case ADD_COMMENT_FAILURE: {
      const { clubId, replyingTo } = action.payload;
      if (replyingTo) {
        return updateSubmittingReplyStatus(state, clubId, replyingTo, false);
      }

      return state;
    }
    case ADD_COMMENT_SUCCESS: {
      const { clubId, comment } = action.payload;
      const { parentId } = comment;
      if (parentId) {
        const updatedReplyStatusState = updateSubmittingReplyStatus(state, clubId, parentId, false);
        return insertOrUpdateComment(updatedReplyStatusState, clubId, comment);
      }
      return insertOrUpdateComment(state, clubId, comment);
    }
    case DELETE_COMMENT_SUCCESS:
    case RESTORE_COMMENT_SUCCESS: {
      const { clubId, comment } = action.payload;
      return insertOrUpdateComment(state, clubId, comment);
    }
    case DELETE_COMMENT_REQUEST: {
      const { clubId, commentId, parentId } = action.payload;
      return updateDeletingStatus(state, clubId, commentId, parentId, true);
    }
    case DELETE_COMMENT_FAILURE: {
      const { clubId, commentId, parentId } = action.payload;
      return updateDeletingStatus(state, clubId, commentId, parentId, false);
    }
    case RESTORE_COMMENT_REQUEST: {
      const { clubId, commentId, parentId } = action.payload;
      return updateRestoringStatus(state, clubId, commentId, parentId, true);
    }
    case RESTORE_COMMENT_FAILURE: {
      const { clubId, commentId, parentId } = action.payload;
      return updateRestoringStatus(state, clubId, commentId, parentId, false);
    }
    default:
      return state;
  }
};

const checkingPermissions = (state = [], action) => {
  switch (action.type) {
    case COMMENTS_PERMISSIONS_REQUEST: {
      const { clubId } = action.payload;
      return [...state, clubId];
    }
    case COMMENTS_PERMISSIONS_RECEIVE:
    case COMMENTS_PERMISSIONS_FAILURE: {
      const { clubId } = action.payload;
      return [...state].filter((loadingId) => loadingId !== clubId);
    }
    default:
      return state;
  }
};

export default combineReducers({
  canAdminister,
  canComment,
  checkingPermissions,
  comments,
  currentlySubmittingTopLevelComments,
  loadedComments,
  loadingComments,
  loadingFailures,
});
