import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cushion, Typography } from '@pitchero/react-ui';
import ShieldImportantIcon from 'icons/ShieldImportant';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { getCurrentClub } from 'store/clubs/selectors';
import IconWithTheme from 'components/icon-with-theme';
import ErrorLinks from './error-links';

const showReportDialog = async (eventId) => {
  const sentrylib = await import('lib/sentry');
  const { Sentry } = sentrylib.default();
  Sentry.showReportDialog({ eventId });
};

class GenericError extends React.Component {
  componentDidMount() {
    const { eventId } = this.props;
    if (eventId) {
      showReportDialog(eventId);
    }
  }

  componentDidUpdate(prevProps) {
    const { eventId } = this.props;
    const { eventId: prevEventId } = prevProps;
    if (eventId && eventId !== prevEventId) {
      showReportDialog(eventId);
    }
  }

  render() {
    const { club } = this.props;
    return (
      <Cushion top="xxlarge">
        <Typography preset="errorCode" component="div" align="center" color="silver">
          <IconWithTheme>
            <ShieldImportantIcon size={120} />
          </IconWithTheme>
        </Typography>
        <Typography align="center" component="div" preset="subtitle--large">
          <Trans i18nKey="common:page_data_error_main_message">
            Sorry, something`s gone wrong...
          </Trans>
        </Typography>
        <Cushion top="medium">
          <Typography align="center" component="div" preset="body--large" color="dustygrey">
            <Trans i18nKey="common:page_data_error_sub_message">
              If this persists please contact a member of our support team
            </Trans>
          </Typography>
        </Cushion>
        <Cushion top="medium">
          <Typography align="center" component="div" preset="body--large" color="dustygrey">
            <Trans i18nKey="common:other_links_header">Other links:</Trans>
          </Typography>
        </Cushion>
        {club && <ErrorLinks club={club} />}
      </Cushion>
    );
  }
}

GenericError.defaultProps = {
  club: null,
  eventId: null,
};

GenericError.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  club: CLUB_PROP_TYPES,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps)(GenericError);
