import { combineReducers } from 'redux';
import { reject, uniq } from 'lodash';
import {
  CLUB_VIDEO_REQUEST,
  CLUB_VIDEO_RECEIVE,
  CLUB_VIDEO_FAILURE,
  VIDEO_LIST_RECEIVE,
  VIDEO_LIST_FAILURE,
  VIDEO_WATCHED,
  LOGIN_SUCCESS,
} from 'store/action-types';

const loadingVideos = (state = [], action) => {
  switch (action.type) {
    case CLUB_VIDEO_REQUEST: {
      return [...state, action.payload.videoId];
    }
    case CLUB_VIDEO_RECEIVE:
    case CLUB_VIDEO_FAILURE: {
      const videoIds = [...state];
      return reject(videoIds, (loadingId) => loadingId === action.payload.videoId);
    }
    default:
      return state;
  }
};

const loadedVideos = (state = [], action) => {
  switch (action.type) {
    case CLUB_VIDEO_RECEIVE: {
      return [...state, action.payload.videoId];
    }
    default:
      return state;
  }
};

const videos = (state = [], action) => {
  switch (action.type) {
    case CLUB_VIDEO_RECEIVE: {
      return [...state, action.payload.video];
    }
    default:
      return state;
  }
};

const relatedVideos = (state = {}, action) => {
  switch (action.type) {
    case CLUB_VIDEO_RECEIVE: {
      return { ...state, [action.payload.videoId]: action.payload.related };
    }
    default:
      return state;
  }
};

const videoLists = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_LIST_RECEIVE: {
      const { page, search, teamId, videos: videoList } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      return {
        ...state,
        [key]: videoList,
      };
    }

    default:
      return state;
  }
};

const watchedVideos = (state = [], action) => {
  switch (action.type) {
    case VIDEO_WATCHED:
      return uniq([...state, action.payload.videoId]);
    default:
      return state;
  }
};

const loadingErrors = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {};
    case VIDEO_LIST_RECEIVE: {
      const { page, search, teamId } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      const errors = { ...state };
      delete errors[key];
      return errors;
    }
    case VIDEO_LIST_FAILURE: {
      const { page, search, teamId } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      return {
        ...state,
        [key]: action.payload,
      };
    }

    case CLUB_VIDEO_FAILURE: {
      const { videoId } = action.payload;
      return {
        ...state,
        [videoId]: action.payload,
      };
    }

    default:
      return state;
  }
};

const pagination = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_LIST_RECEIVE: {
      const { teamId = 0, pagination: teamPagination, search } = action.payload;
      const key = search ? `${teamId}-${search}` : teamId;
      return {
        ...state,
        [key]: teamPagination,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  loadingVideos,
  loadingErrors,
  loadedVideos,
  pagination,
  videos,
  relatedVideos,
  videoLists,
  watchedVideos,
});
