import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { attemptTokenLogin } from 'store/auth/actions';
import { pitcheroDomain } from 'lib/env';

/**
 * The purpose of this component is that when a user loads a page in this NodeJS
 * application and they are NOT logged in we load an iFrame to the PHP app to see
 * if the user is logged in there, if they are we log them in on this app too.
 *
 * This relies on javascripts `window.parent.postMessage` which the loaded iFrame
 * calls to pass back a message to the current page and is handled in `onReceiveMessage`
 */
const GlobalAuthLoggedOut = ({ tokenLogin }) => {
  const [listenerAttached, setListenerAttached] = useState(false);

  const onReceiveMessage = ({ data, origin }) => {
    if (origin === `https://${pitcheroDomain()}`) {
      const { type, loggedIn, token } = data;
      if (type === 'LOGIN_CHECK_RESULT' && loggedIn && token) {
        tokenLogin(token);
      }
    }
  };

  // Add an event listener when the component mounts.
  useEffect(() => {
    if (window.attachEvent) {
      // Internet Explorer
      window.attachEvent('onmessage', onReceiveMessage);
      setListenerAttached(true);
    } else if (window.addEventListener) {
      // Opera/Mozilla/Webkit
      window.addEventListener('message', onReceiveMessage, false);
      setListenerAttached(true);
    }
  }, []);

  if (!listenerAttached) {
    // Do not put out the iframe until the eventListener is ready.
    return null;
  }

  return (
    <iframe
      src={`https://${pitcheroDomain()}/auth/global`}
      height={0}
      width={0}
      title="globalAuthLoggedOut"
      style={{
        border: 0,
        display: 'block',
      }}
    />
  );
};

GlobalAuthLoggedOut.propTypes = {
  tokenLogin: PropTypes.func.isRequired,
};

const dispatchToProps = {
  tokenLogin: attemptTokenLogin,
};

export default connect(null, dispatchToProps)(GlobalAuthLoggedOut);
