import { combineReducers } from 'redux';
import { ADVERTS_SETUP_UPDATE } from 'store/action-types';

const advertsSetup = (state = false, action) => {
  switch (action.type) {
    case ADVERTS_SETUP_UPDATE:
      return action.payload.advertsSetup;
    default:
      return state;
  }
};

export default combineReducers({
  advertsSetup,
});
