import { PAGE_DATA_LOAD } from 'store/action-types';
import { combineReducers } from 'redux';

const lastLoadedPageData = (state = null, action) => {
  switch (action.type) {
    case PAGE_DATA_LOAD:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({ lastLoadedPageData });
