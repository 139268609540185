import { combineReducers } from 'redux';
import {
  MATCH_REPORTS_PAGE_REQUEST,
  MATCH_REPORTS_PAGE_RECEIVE,
  MATCH_REPORTS_PAGE_FAILURE,
} from 'store/action-types';
import { reject, uniq } from 'lodash';

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case MATCH_REPORTS_PAGE_REQUEST:
      return [...state, action.payload.teamId];
    case MATCH_REPORTS_PAGE_FAILURE:
    case MATCH_REPORTS_PAGE_RECEIVE: {
      const { teamId } = action.payload;
      const teamIds = [...state];
      return reject(teamIds, (loadingId) => loadingId === teamId);
    }
    default:
      return state;
  }
};

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case MATCH_REPORTS_PAGE_RECEIVE:
      return uniq([...state, action.payload.teamId]);
    default:
      return state;
  }
};

const reports = (state = [], action) => {
  switch (action.type) {
    case MATCH_REPORTS_PAGE_RECEIVE:
      return [...state, ...action.payload.reports];
    default:
      return state;
  }
};

const seasons = (state = [], action) => {
  switch (action.type) {
    case MATCH_REPORTS_PAGE_RECEIVE:
      return action.payload.seasons;
    default:
      return state;
  }
};

const loadingError = (state = [], action) => {
  switch (action.type) {
    case MATCH_REPORTS_PAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case MATCH_REPORTS_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoading,
  hasLoaded,
  reports,
  seasons,
  loadingError,
});
