import { combineReducers } from 'redux';
import { reject } from 'lodash';
import { HOMEPAGE_REQUEST, HOMEPAGE_RECEIVE, HOMEPAGE_FAILURE } from 'store/action-types';

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case HOMEPAGE_RECEIVE:
      return [...state, action.payload.clubId];
    case HOMEPAGE_REQUEST:
    case HOMEPAGE_FAILURE: {
      const clubIds = [...state];
      return reject(clubIds, (clubId) => clubId === action.payload.clubId);
    }
    default:
      return state;
  }
};

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case HOMEPAGE_REQUEST:
      return [...state, action.payload.clubId];
    case HOMEPAGE_RECEIVE:
    case HOMEPAGE_FAILURE: {
      const clubIds = [...state];
      return reject(clubIds, (clubId) => clubId === action.payload.clubId);
    }
    default:
      return state;
  }
};

const pageData = (state = {}, action) => {
  switch (action.type) {
    case HOMEPAGE_RECEIVE: {
      return {
        ...state,
        [action.payload.clubId]: action.payload.pageData,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  hasLoaded,
  currentlyLoading,
  pageData,
});
