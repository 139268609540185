import { combineReducers } from 'redux';
import {
  DRAFT_THEME_REQUEST,
  DRAFT_THEME_RECEIVE,
  DRAFT_THEME_FAILURE,
  DRAFT_THEME_CLEAR,
  SELECT_SEASON,
} from 'store/action-types';
import clubTheme from 'lib/club-theme';

const clubs = (state = {}) => {
  return state;
};

const currentClubId = (state = null) => {
  return state;
};

const isIframePreview = (state = null) => {
  return state;
};

const activeSeasonId = (state = null, action) => {
  switch (action.type) {
    case SELECT_SEASON:
      return action.payload;
    default:
      return state;
  }
};

const themeDraftId = (state = null, action) => {
  switch (action.type) {
    case DRAFT_THEME_CLEAR:
      return null;
    default:
      return state;
  }
};

const loadingDraftTheme = (state = false, action) => {
  switch (action.type) {
    case DRAFT_THEME_REQUEST:
      return true;
    case DRAFT_THEME_RECEIVE:
    case DRAFT_THEME_FAILURE:
      return false;
    default:
      return state;
  }
};

const loadedDraftThemes = (state = [], action) => {
  switch (action.type) {
    case DRAFT_THEME_RECEIVE: {
      const { draftId } = action.payload;
      return [...state, draftId];
    }
    default:
      return state;
  }
};

const draftTheme = (state = {}, action) => {
  switch (action.type) {
    case DRAFT_THEME_CLEAR:
      return {};
    case DRAFT_THEME_RECEIVE: {
      const { data } = action.payload;
      return data;
    }
    default:
      return state;
  }
};

const theme = (state = {}, action) => {
  switch (action.type) {
    case DRAFT_THEME_CLEAR: {
      const { club } = action.payload;
      return clubTheme(club);
    }
    case DRAFT_THEME_RECEIVE: {
      const { club, data } = action.payload;
      return clubTheme({
        ...club,
        ...data.theme,
      });
    }
    default:
      return state;
  }
};

export default combineReducers({
  activeSeasonId,
  clubs,
  currentClubId,
  draftTheme,
  loadingDraftTheme,
  loadedDraftThemes,
  isIframePreview,
  theme,
  themeDraftId,
});
