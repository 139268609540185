import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoggedInUser } from 'store/membership/selectors';
import { hasCheckedLoginState as getHasCheckedLoginState } from 'store/auth/selectors';

const UserDataLayerManager = ({ user, hasCheckedLoginState }) => {
  const fireUserUpdatedEvent = () => {
    window.dataLayer.push({ event: 'userUpdated', user });
  };

  const fireUserLoginCheckedEvent = () => {
    window.dataLayer.push({
      event: 'userLoginChecked',
      hasCheckedUserLoggedIn: hasCheckedLoginState,
    });
  };

  useEffect(() => {
    if (!window.dataLayer) {
      return;
    }

    fireUserUpdatedEvent();
    fireUserLoginCheckedEvent();
  }, [user]);

  useEffect(() => {
    if (!window.dataLayer) {
      return;
    }

    fireUserLoginCheckedEvent();
  }, [hasCheckedLoginState]);

  return null;
};

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  hasCheckedLoginState: getHasCheckedLoginState(state),
});

UserDataLayerManager.propTypes = {
  user: PropTypes.shape(),
  hasCheckedLoginState: PropTypes.bool.isRequired,
};
UserDataLayerManager.defaultProps = {
  user: {},
};
export default connect(mapStateToProps)(UserDataLayerManager);
