export default {
  presets: {
    cards: {
      className: 'grid-cards',
      columns: 'repeat(auto-fill, minmax(240px, 1fr))',
      columnGap: 'medium',
      rowGap: 'large',
      meta: {
        demoChildrenCount: 8,
      },
    },
    profiles: {
      className: 'ie-grid-three-col',
      rowGap: 'small',
      columns: '1fr',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'palm',
          props: {
            columns: 'repeat(2, minmax(0, 1fr))',
            rowGap: 'medium',
          },
        },
        {
          minWidth: 'lap',
          props: {
            columns: 'repeat(3, minmax(0, 1fr))',
            rowGap: 'medium',
          },
        },
      ],
    },
    gallery: {
      className: 'grid-gallery',
      columns: 'repeat(12, 1fr)',
      rowGap: 6,
      columnGap: 6,
      responsive: [
        {
          minWidth: 'desk',
          props: { columns: 'repeat(12, 1fr)' },
        },
        {
          minWidth: 'palm',
          maxWidth: 'desk',
          props: {
            columns: 'repeat(9, 1fr)',
            rowGap: 3,
            columnGap: 3,
          },
        },
        {
          maxWidth: 'palm',
          props: {
            columns: 'repeat(6, 1fr)',
            rowGap: 3,
            columnGap: 3,
          },
        },
      ],
    },
    activityStream: {
      className: 'grid-activity-stream',
      columns: 'minmax(0, 1fr)',
      rowGap: 'small',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            columns: 'repeat(3, minmax(216px, 1fr))',
            rowGap: 'large',
          },
        },
        {
          minWidth: 'desk',
          props: { columns: 'repeat(4, 1fr)' },
        },
      ],
    },
    articleGroup: {
      className: 'grid-article-group',
      columns: '1fr 3fr',
      responsive: [
        {
          minWidth: 0,
          maxWidth: 'tab',
          props: { columns: 'minmax(0, 1fr)' },
        },
        {
          minWidth: 'desk',
          props: { columns: '1fr 3fr' },
        },
      ],
    },
    documentGroup: {
      className: 'grid-document-group',
      columns: 'minmax(260px, 1fr) minmax(260px, 3fr)',
      responsive: [
        {
          minWidth: 0,
          maxWidth: 'tab',
          props: { columns: 'minmax(0, 1fr)' },
        },
        {
          minWidth: 'desk',
          props: { columns: 'minmax(260px, 1fr) minmax(260px, 3fr)' },
        },
      ],
    },
    homepageNews: {
      className: 'grid-homepage-news',
      columns: 'minmax(0, 1fr)',
      rowGap: 'small',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'palm',
          props: { columns: '1fr 1fr', rowGap: 'large' },
        },
        {
          minWidth: 'lap',
          props: { columns: '1fr 1fr 1fr 1fr', rows: '3fr 1fr 3fr' },
        },
      ],
    },
    homepageGallery: {
      className: 'grid-homepage-gallery',
      columns: 'minmax(0, 1fr)',
      rowGap: 'small',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'palm',
          props: { columns: '1fr 1fr', rowGap: 'medium' },
        },
        {
          minWidth: 'lap',
          props: { columns: '1fr 1fr 1fr 1fr', rowGap: 'medium' },
        },
      ],
    },
    clubEvent: {
      className: 'grid-club-event',
      areas: `'article  attendance'
              'article  attendees'
              'comments .'`,
      rows: 'auto 1fr',
      columns: 'minmax(0, 1fr) 324px',
      columnGap: 'medium',
      responsive: [
        {
          maxWidth: 1000,
          props: {
            columns: 'minmax(0, 1fr)',
            areas: `'attendance'
                 'article'
                 'attendees'
                 'comments'`,
          },
        },
      ],
    },
    training: {
      className: 'grid-training',
      columns: 'minmax(0, 1fr) 400px',
      columnGap: 'large',
      alignItems: 'start',
      rows: 'auto auto 1fr',
      rowGap: 'medium',
      areas: `'overview  availability'
              'overview  attendees'
              'details attendees'`,
      responsive: [
        {
          maxWidth: 1000,
          props: {
            columns: 'minmax(0, 1fr)',
            areas: `'availability'
                 'overview'
                 'details'
                 'attendees'`,
          },
        },
      ],
    },
    trainingHistoric: {
      className: 'grid-training-historic',
      columns: 'minmax(0, 1fr) 400px',
      columnGap: 'large',
      alignItems: 'start',
      rowGap: 'medium',
      areas: `'overview  attendees'
              'details attendees'`,
      responsive: [
        {
          maxWidth: 1000,
          props: {
            columns: 'minmax(0, 1fr)',
            areas: `'overview'
                 'details'
                 'attendees'`,
          },
        },
      ],
    },
    profileHeader: {
      className: 'grid-profile-header',
      columns: '240px minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: 'large',
      rowGap: 'medium',
      areas: `'avatar name season'
              'avatar stats fixturesSummary'`,
      responsive: [
        {
          maxWidth: 'tab',
          props: {
            areas: `'avatar name'
                 'season season'
                 'stats stats'
                 'fixturesSummary fixturesSummary'`,
            columns: '120px minmax(0, 1fr)',
            columnGap: 'medium',
            alignItems: 'center',
          },
        },
      ],
    },
    teamHomepageMedia: {
      className: 'ie-grid-two-col',
      columns: 'minmax(0, 1fr)',
      rowGap: 'large',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'palm',
          props: {
            columns: 'repeat(2, minmax(0, 1fr))',
            rowGap: 'large',
          },
        },
      ],
    },
    'icon-label': {
      alignItems: 'start',
      className: 'grid-icon-label',
      columns: '28px 1fr',
      columnGap: 'small',
    },
    'match-centre-event-summaries': {
      alignItems: 'start',
      className: 'grid-match-centre-event-summaries ie-flex',
      columns: '1fr 80px 1fr',
      columnGap: 'small',
      responsive: [
        {
          maxWidth: 450,
          props: { columnGap: 'xsmall' },
        },
      ],
    },
    documents: {
      columns: '1fr',
      columnGap: 'large',
      responsive: [
        {
          minWidth: 'palm',
          props: { columns: 'repeat(auto-fill, minmax(360px, 1fr))' },
        },
      ],
    },
    productGrid: {
      columns: '1fr',
      rowGap: 'small',
      columnGap: 'medium',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            columns: '1fr 1fr',
            rowGap: 'medium',
          },
        },
        {
          minWidth: 'fullHeader',
          props: {
            columns: '1fr 1fr 1fr 1fr',
          },
        },
      ],
    },
    relatedContentSidebar: {
      columns: '1fr',
      rowGap: 'small',
      columnGap: 'small',
      responsive: [
        {
          minWidth: 700,
          maxWidth: 'lap',
          props: {
            columns: 'minmax(0, 1fr) minmax(0, 1fr)',
            rowGap: 'medium',
          },
        },
      ],
    },
    relatedContent: {
      columns: '1fr',
      rowGap: 'small',
      columnGap: 'small',
      responsive: [
        {
          minWidth: 'lap',
          props: {
            columns: 'minmax(0, 1fr) minmax(0, 1fr)',
            rowGap: 'medium',
          },
        },
      ],
    },
  },
};
