import React from 'react';
import { Cushion, Rectangle, ThemeProvider, Shadow, Space, Typography } from '@pitchero/react-ui';
import pitcheroTheme from 'lib/pitchero-theme';
import PropTypes from 'prop-types';
import PitcheroLogo from 'components/pitchero-logo';
import { Trans } from 'lib/i18n';

const PitcheroFrame = ({ children }) => {
  return (
    <ThemeProvider theme={pitcheroTheme}>
      <Rectangle fill="white" style={{ height: '100vh' }} component="div">
        <Shadow shadow="dark">
          <Rectangle fill="primary">
            <Cushion vertical="small" left="medium" component="div">
              <Space bottom="xsmall">
                <Typography color="white" preset="tag--small" component="div">
                  <Trans i18nKey="common:powered_by">Powered by</Trans>
                </Typography>
              </Space>
              <PitcheroLogo color="white" />
            </Cushion>
          </Rectangle>
        </Shadow>
        <div>
          <div>{children}</div>
        </div>
      </Rectangle>
    </ThemeProvider>
  );
};

PitcheroFrame.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PitcheroFrame;
