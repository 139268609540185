export default function (
  primary,
  primaryContrast,
  primaryDark,
  primaryDarkContrast,
  primaryLight,
  primaryLightContrast,
  secondary,
  secondaryContrast,
  secondaryLight,
  headerBgMobile,
  footerBg,
  footerBgContrast,
  isFreePackage,
) {
  const primaryContrastIsLight = primaryContrast === '#FFFFFF';
  const primaryBgHighlight = primaryContrastIsLight
    ? 'rgba(0, 0, 0, 0.25)'
    : 'rgba(255, 255, 255, 0.25)';

  const colours = {
    primary,
    primaryContrast,
    primaryDark,
    primaryDarkContrast,
    primaryLight,
    primaryLightContrast,
    secondary,
    secondaryContrast,
    secondaryLight,
    headerBg: primary,
    headerBgMobile,
    headerContrast: primaryContrast,
    footerBg,
    footerBgContrast,
    navBg: primaryBgHighlight,
    featuredContentItemPreviewBg: primaryBgHighlight,
    featuredContentTypographyColor: primaryContrast,
    featuredContentTag: secondaryContrast,
    featuredContentTagBg: secondary,
    featuredContentProgressBg: primaryDarkContrast,
    homepageTopBannerBg: '#fff',
    gold: '#FFD058',
    positive: '#00D296',
    neutral: '#FFCC63',
    negative: '#F15E64',
    availability_available: '#00D296',
    availability_not_sure: '#FFCC63',
    availability_unavailable: '#F15E64',
    availability_not_set: '#00a0d1',
    black: '#333',
    dovegrey: '#666',
    dustygrey: '#767676',
    silver: '#c8c8c8',
    alto: '#d6d6d6',
    mercury: '#e7e7e7',
    seashellgrey: '#F1F1F1',
    alabaster: '#F7F7F7',
    white: '#FFF',
    outcomeBgL: '#F15E64',
    outcomeBgW: '#00D296',
    outcomeBgD: '#bebcbc',
    outcomeBgP: '#bebcbc',
    outcomeBgA: '#bebcbc',
    outcomeBgC: '#bebcbc',
    outcomeBgT: '#bebcbc',
    facebook: '#4267b2',
    twitter: '#38A1F3',
    notification: '#00a0d1',
    dismissalsB: '#6982CB',
    dismissalsCt: '#E06268',
    dismissalsDh: '#454545',
    dismissalsHb: '#F5B765',
    dismissalsHw: '#AEAEAE',
    dismissalsLb: '#5BD4B1',
    dismissalsRo: '#BE7BC0',
    dismissalsRt: '#F08686',
    dismissalsSt: '#ECD340',
    dismissalsTo: '#8BD061',
  };

  if (isFreePackage) {
    return {
      ...colours,
      headerBg: '#fff',
      headerBgMobile: '#FFF',
      headerContrast: '#333',
      navBg: '#F1F1F1',
      featuredContentItemPreviewBg: '#f5f5f5',
      featuredContentTypographyColor: '#333',
      featuredContentProgressBg: '#333',
      featuredContentTag: primaryContrast,
      featuredContentTagBg: primary,
      homepageTopBannerBg: null,
    };
  }

  return colours;
}
