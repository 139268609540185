import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasBeenLoggedIn, isLoggedIn } from 'store/auth/selectors';
import GlobalAuthLoggedOut from './logged-out';

const GlobalLogin = ({ hasLoggedIn, loggedIn }) => {
  useEffect(() => {
    if (loggedIn && !document.getElementById('globallogin')) {
      const iframe = document.createElement('iframe');
      iframe.src = '/global-login';
      iframe.setAttribute('id', 'globallogin');
      iframe.setAttribute('height', 0);
      iframe.setAttribute('width', 0);
      iframe.setAttribute('style', 'border:0; display: block;');
      document.body.appendChild(iframe);
    }
  }, [loggedIn]);

  if (loggedIn || hasLoggedIn) {
    return null;
  }

  return <GlobalAuthLoggedOut />;
};

GlobalLogin.propTypes = {
  hasLoggedIn: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoggedIn: hasBeenLoggedIn(state),
  loggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(GlobalLogin);
