import React from 'react';
import { isEmpty, get } from 'lodash';
import initializeStore from 'store';
import Cookies from 'universal-cookie';
import clubTheme from 'lib/club-theme';

const isServer = typeof window === 'undefined';
const NEXT_REDUX_STORE = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[NEXT_REDUX_STORE]) {
    window[NEXT_REDUX_STORE] = initializeStore(initialState);
  }
  return window[NEXT_REDUX_STORE];
}

function getHost(context) {
  const host = !isEmpty(context.ctx.req) ? context.ctx.req.hostname : null;
  const windowHost = isServer ? process.env.APP_DOMAIN : window.location.hostname;
  return host || windowHost;
}

function getIsApp(context) {
  const { req } = context.ctx;

  if (isEmpty(req)) {
    return false;
  }

  return req.query.app === 'club' || !!req.session.isApp;
}

function getShopBannerClosed(context) {
  const { req } = context.ctx;

  if (isEmpty(req)) {
    return false;
  }

  return new Cookies(req.headers.cookie).get('SHOP_BANNER_CLOSED');
}

function getConfirmedOverEighteen(context) {
  const { req } = context.ctx;

  if (isEmpty(req)) {
    return null;
  }

  return new Cookies(req.headers.cookie).get('CONFIRMED_OVER_EIGHTEEN');
}

export default (App) =>
  class AppWithRedux extends React.Component {
    static async getInitialProps(context) {
      const initialState = {
        common: {
          host: getHost(context),
          isApp: getIsApp(context),
        },
        shop: {
          bannerClosed: getShopBannerClosed(context),
        },
        ageGate: {
          confirmedOverEighteen: getConfirmedOverEighteen(context),
        },
      };

      const hasJWT = !!get(context.ctx, 'req.session.jwt');
      initialState.auth = {
        hasBeenLoggedIn: hasJWT,
        isLoggedIn: hasJWT,
      };

      // Design builder preview query params
      const themeDraftId = get(context, 'ctx.req.query.preview_theme');
      const isIframePreview = !!get(context, 'ctx.req.query.is_frame');

      // If the club was loaded onto the request by the club-middleware then add to initialState
      const club = get(context, 'ctx.req.club');
      if (club) {
        const { id: clubId } = club;
        initialState.clubs = {
          clubs: {
            [clubId]: club,
          },
          currentClubId: clubId,
          theme: clubTheme(club),
          themeDraftId,
          isIframePreview,
        };
      }

      const reduxStore = getOrCreateStore(initialState);

      // Provide the store to getInitialProps of pages
      const appContext = context;
      appContext.ctx.reduxStore = reduxStore;
      appContext.ctx.isServer = isServer;
      appContext.ctx.isClient = !isServer;

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  };
