import {
  NETWORK_PANEL_RECEIVE,
  NETWORK_PANEL_REQUEST,
  NETWORK_PANEL_REQUEST_SUCCESS,
  NETWORK_PANEL_FAILURE,
  NETWORK_PANEL_OPEN,
  NETWORK_PANEL_CLOSE,
  MEMBERSHIP_APPLY_CLUB_MEMBER_REQUEST,
  MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE,
  UNAUTHORISED_RESPONSE,
} from 'store/action-types';
import { find, get, uniqBy } from 'lodash';
import { combineReducers } from 'redux';

const submittingApplication = (state = false, action) => {
  switch (action.type) {
    case MEMBERSHIP_APPLY_CLUB_MEMBER_REQUEST:
      return true;
    case MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE:
      return false;
    default:
      return state;
  }
};

const networkPanelLoading = (state = false, action) => {
  switch (action.type) {
    case NETWORK_PANEL_REQUEST:
      return true;
    case NETWORK_PANEL_FAILURE:
    case NETWORK_PANEL_REQUEST_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
};

const addClubPendingApplication = (state, clubId) => {
  const clubs = get(state, 'clubs', []);
  const club = find(clubs, { id: clubId });

  if (!club) {
    return state;
  }

  return {
    ...state,
    clubs: uniqBy(
      [
        {
          ...club,
          hasPendingMembershipApplication: true,
        },
        ...clubs,
      ],
      'id',
    ),
  };
};

const clearNetworkPanelCache = async () => {
  try {
    const cache = await caches.open('networkPanel');
    await cache.delete('/api/v2/users/network-panel');
  } catch {
    // Browser likely doesn't support caches
  }
};

const networkPanel = (state = {}, action) => {
  switch (action.type) {
    case UNAUTHORISED_RESPONSE:
      clearNetworkPanelCache();
      return {};
    case NETWORK_PANEL_RECEIVE:
      return action.payload;
    case MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE: {
      const { clubId } = action.payload;
      return addClubPendingApplication(state, clubId);
    }
    default:
      return state;
  }
};

const networkPanelOpen = (state = false, action) => {
  switch (action.type) {
    case NETWORK_PANEL_OPEN:
      return true;
    case NETWORK_PANEL_CLOSE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  networkPanel,
  networkPanelLoading,
  networkPanelOpen,
  submittingApplication,
});
