import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withTheme } from '@pitchero/react-ui';
import HeaderContext from './context';

const HeaderProvider = ({ children, showingSkins, uiTheme }) => {
  const [values, setValues] = useState({
    backgroundImage: null,
    height: 0,
    backgroundColor: get(uiTheme, 'colors.headerBg'),
  });
  const { backgroundColor, backgroundImage, height } = values;

  const updateImage = (newImage) => {
    if (backgroundImage !== newImage && !showingSkins) {
      setValues({
        backgroundColor,
        backgroundImage: newImage,
        height,
      });
    }
  };

  const updateHeight = (newHeight) => {
    if (height !== newHeight && !showingSkins) {
      setValues({
        backgroundColor,
        backgroundImage,
        height: newHeight,
      });
    }
  };

  const clear = () => {
    if ((backgroundImage || height) && !showingSkins) {
      setValues({
        backgroundColor: get(uiTheme, 'colors.headerBg'),
        backgroundImage: null,
        height: 0,
      });
    }
  };

  return (
    <HeaderContext.Provider
      value={{
        backgroundColor,
        backgroundImage,
        height,
        clear,
        showingSkins,
        updateImage,
        updateHeight,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

HeaderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  showingSkins: PropTypes.bool.isRequired,
  uiTheme: PropTypes.shape().isRequired,
};
export default withTheme(HeaderProvider);
